<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-dns</v-icon>
          <h1 class="darkGrey--text">Items</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Agenda Styles
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              height="40"
              color="orange lighten-2"
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="18">mdi-plus</v-icon> Add Item
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="openModal('create', null, null, board)"
              v-for="board in activeBoards"
              :key="board.name + Math.random()"
            >
              <v-list-item-title>{{ board.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-tabs
          v-model="tab"
          centered
          color="secondary"
          background-color="transparent"
          class="custom-tab"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1">
            <span class="d-flex align-center">
              <v-icon class="d-flex mr-3">mdi-account-group</v-icon>
              In a Meeting
            </span>
          </v-tab>
          <v-tab href="#tab-2">
            <span class="d-flex align-center">
              <v-icon class="d-flex mr-3">mdi-sign-caution</v-icon>
              Parked
            </span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
          class="transparent"
        >

          <v-tab-item
            value="tab-1"
            class="transparent"
          >

            <v-row class="d-flex mb-6 mt-6">
              <v-col
                class="d-flex"
                cols="4"
              >
                <v-select
                  :key="board.name + Math.random()"
                  :items="activeBoards"
                  label="Board"
                  :clearable="true"
                  v-model="inAMeetingBoardSelected"
                  :item-value="item => item.id"
                  :item-text="item => item.name"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="5" class="d-flex align-center">
                <v-checkbox
                  color="secondary"
                  label="Show only my items"
                  v-model="showOnlyMyItems"
                  hide-details
                  class="d-flex mr-6 custom-checkbox-small"
                ></v-checkbox>
                <v-checkbox
                  color="secondary"
                  label="Display Alias"
                  v-model="displayAlias"
                  hide-details
                  class="d-flex mr-6 custom-checkbox-small"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="3"
              >
                <v-text-field
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :server-items-length="totalItemsCount"
                    :loading="loading"
                    :search="search"
                    class="elevation-1"
                    :footer-props="{
                      'items-per-page-options': [10, 20, 30]
                    }"
                  >
                    <template v-slot:item.editDraft="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            depressed
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="coverSheetDraft(item)"
                          >
                            <v-icon size="20">
                              mdi-file-edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>
                            Cover Sheet Draft
                          </span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.title="{ item }">
                      <span class="py-6 d-flex">
                        <span class="text-caption">
                          {{ item.title.slice(0,textCut) }}
                          <v-tooltip
                            top
                            class="tooltip-no-opacity"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="item.title.length > textCut"
                                width="24"
                                height="18"
                                min-width="24"
                                min-height="18"
                                class="ml-2 white--text"
                                depressed
                                color="lightGrey"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-dots-horizontal</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-caption">
                              <v-card elevation="0" width="600" color="transparent" class="white--text pa-4">
                                {{ itemTitle(item) }}
                              </v-card>
                            </span>
                          </v-tooltip>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.board="{ item }">
                      <span class="py-6 d-flex flex-column">
                        <span class="font-weight-bold">
                          {{ item.board.name }}
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.meeting="{ item }">
                      <span class="d-flex py-6">
                        {{ item.meeting }}
                      </span>
                    </template>
                    <template v-slot:item.date="{ item }">
                      <span class="d-flex py-6 text-caption">
                        {{ item.date }}<br>
                        {{ item.time }}<br>
                        {{ item.weekday }}
                      </span>
                    </template>
                    <template v-slot:item.tags="{ item }">

                      <v-tooltip top v-if="item.tags.length>3">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.tags.split(',').length }}
                          </v-chip>
                        </template>
                        <span class="custom-tooltip-list">
                          <v-row>
                            <v-col>
                              <span class="custom-tooltip-title">
                                TAGS
                              </span>
                              <ul>
                                <li v-for="item in item.tags.split(',')" :key="item + Math.random()">
                                  {{ item }}
                                </li>
                              </ul>
                            </v-col>
                          </v-row>
                        </span>
                      </v-tooltip>
                      <span v-if="item.tags.length<=3" class="d-flex flex-column">
                        <span v-for="item in item.tags.split(',')" :key="item + Math.random()">
                          {{ item }}
                        </span>
                      </span>

                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div class="d-flex flex-row justify-center">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                              @click="itemAttachments(item)"
                            >
                              <v-icon size="20">
                                mdi-paperclip
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Attachments
                          </span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                              @click="viewItem(item)"
                            >
                              <v-icon size="20">
                                mdi-eye-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            View details
                          </span>
                        </v-tooltip>

                        <v-menu transition="slide-x-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon size="20">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item-group>
                              <v-list-item
                                v-for="(itemMenu, index) in itemsMenuFiltered(item)"
                                :key="index + Math.random()"
                                @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                                dense
                              >
                                <v-list-item-icon>
                                  <v-icon
                                    :color="itemMenu.type? itemMenu.type:'darkGrey'"
                                  >
                                    {{ itemMenu.icon }}
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title
                                    :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                                  >
                                    {{ itemMenu.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>

                      </div>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>

          </v-tab-item>

          <v-tab-item
            value="tab-2"
            class="transparent"
          >

            <v-row class="d-flex mb-6 mt-6">
              <v-col
                class="d-flex"
                cols="4"
              >
                <v-select
                  :key="board.name + Math.random()"
                  label="Board"
                  :clearable="true"
                  :items="activeBoards"
                  v-model="parkedBoardSelected"
                  :item-value="item => item.id"
                  :item-text="item => item.name"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="5" class="d-flex align-center">
                <v-checkbox
                  color="secondary"
                  label="Show only my items"
                  hide-details
                  class="d-flex mr-6 custom-checkbox-small"
                ></v-checkbox>
                <v-checkbox
                  color="secondary"
                  label="Display Alias"
                  v-model="displayAlias"
                  hide-details
                  class="d-flex mr-6 custom-checkbox-small"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="3"
              >
                <v-text-field
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    :server-items-length="totalItemsCount"
                    class="elevation-1"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    :footer-props="{
                      'items-per-page-options': [10, 20, 30]
                    }"
                  >
                    <template v-slot:item.editDraft="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            depressed
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="coverSheetDraft(item)"
                          >
                            <v-icon size="20">
                              mdi-file-edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>
                            Cover Sheet Draft
                          </span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.title="{ item }">
                      <span class="py-6 d-flex">
                        <span class="text-caption">
                          {{ itemTitle(item).slice(0,textCut) }}
                          <v-tooltip
                            top
                            class="tooltip-no-opacity"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="itemTitle(item).length > textCut"
                                width="24"
                                height="18"
                                min-width="24"
                                min-height="18"
                                class="ml-2"
                                depressed
                                color="lightGrey"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-dots-horizontal</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-caption">
                              <v-card elevation="0" width="600" color="transparent" class="white--text pa-4">
                                {{ item.title }}
                              </v-card>
                            </span>
                          </v-tooltip>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.board="{ item }">
                      <span class="py-6 d-flex flex-column">
                        <span class="font-weight-bold">
                          {{ item.board.name }}
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.meeting="{ item }">
                      <span class="d-flex py-6">
                        {{ item.meeting }}
                      </span>
                    </template>
                    <template v-slot:item.date="{ item }">
                      <span class="d-flex py-6 text-caption">
                        {{ item.date }}<br>
                        {{ item.time }}<br>
                        {{ item.weekday }}
                      </span>
                    </template>
                    <template v-slot:item.tags="{ item }">

                      <v-tooltip top v-if="item.tags.length>3">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.tags.split(',').length }}
                          </v-chip>
                        </template>
                        <span class="custom-tooltip-list">
                          <v-row>
                            <v-col>
                              <span class="custom-tooltip-title">
                                TAGS
                              </span>
                              <ul>
                                <li v-for="item in item.tags.split(',')" :key="item + Math.random()">
                                  {{ item }}
                                </li>
                              </ul>
                            </v-col>
                          </v-row>
                        </span>
                      </v-tooltip>
                      <span v-if="item.tags.length<=3" class="d-flex flex-column">
                        <span v-for="item in item.tags.split(',')" :key="item + Math.random()">
                          {{ item }}
                        </span>
                      </span>

                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div class="d-flex flex-row justify-center">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                              @click="viewItem(item)"
                            >
                              <v-icon size="20">
                                mdi-file-edit
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Cover Sheet Draft
                          </span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                              @click="viewMeeting(item)"
                            >
                              <v-icon size="20">
                                mdi-paperclip
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Attachments
                          </span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                              @click="viewItem(item)"
                            >
                              <v-icon size="20">
                                mdi-eye-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            View details
                          </span>
                        </v-tooltip>

                        <v-menu transition="slide-x-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon size="20">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item-group>
                              <v-list-item
                                v-for="(itemMenu, index) in itemsMenuFiltered(item)"
                                :key="index + Math.random()"
                                @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                                dense
                              >
                                <v-list-item-icon>
                                  <v-icon
                                    :color="itemMenu.type? itemMenu.type:'darkGrey'"
                                  >
                                    {{ itemMenu.icon }}
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title
                                    :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                                  >
                                    {{ itemMenu.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>

                      </div>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>

          </v-tab-item>

        </v-tabs-items>

      </v-col>
    </v-row>

    <item-create @reload="getItems" :item="item" v-model="modals.create" :board="board"></item-create>
    <item-edit @reload="getItems" v-model="modals.edit" :item="item"></item-edit>
    <item-type @reload="getItems" v-model="modals.itemType" :item="item"></item-type>
    <item-change-region @reload="getItems" v-model="modals.region" :item="item"></item-change-region>
    <item-cover-sheet @reload="getItems" v-model="modals.coverSheet" :item="item"></item-cover-sheet>
    <item-cover-sheet-draft v-model="modals.coverSheetDraft" :item="item"></item-cover-sheet-draft>
    <item-share-permissions v-model="modals.permissions" :item="item"></item-share-permissions>
    <item-conversations-notes getItems="getItems" v-model="modals.conversations" :item="item"></item-conversations-notes>
    <item-private @reload="getItems" v-model="modals.private" :item="item"></item-private>
    <item-move-to-meeting v-model="modals.move" :item="item"></item-move-to-meeting>
    <item-not-private @reload="getItems" v-model="modals.notPrivate" :item="item"></item-not-private>
    <item-park @reload="getItems" v-model="modals.park" :item="item"></item-park>
    <item-delete @reload="getItems" v-model="modals.delete" :item="item"></item-delete>
    <item-view @reload="getItems" v-model="modals.view" v-if="modals.view" :item="item"></item-view>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import ItemCreate from '@/components/Items/ItemCreate'
import ItemEdit from '@/components/Items/ItemEdit'
import ItemType from '@/components/Items/ItemType'
import ItemChangeRegion from '@/components/Items/ItemChangeRegion'
import ItemCoverSheet from '@/components/Items/ItemCoverSheet'
import ItemCoverSheetDraft from '@/components/Items/ItemCoverSheetDraft'
import ItemSharePermissions from '@/components/Items/ItemSharePermissions'
import ItemConversationsNotes from '@/components/Items/ItemConversationsNotes'
import ItemPrivate from '@/components/Items/ItemPrivate'
import ItemMoveToMeeting from '@/components/Items/ItemMoveToMeeting'
import ItemNotPrivate from '@/components/Items/ItemNotPrivate'
import ItemPark from '@/components/Items/ItemPark'
import ItemDelete from '@/components/Items/ItemDelete'
import ItemView from '@/components/Items/ItemView'
import Item from '@/models/Item'
import axios from 'axios'
import { mapGetters } from 'vuex'
function initialState () {
  return {
    inAMeetingBoardSelected: '',
    parkedBoardSelected: '',
    totalItemsCount: 0,
    activeBoards: [],
    board: {},
    item: {},
    items: [],
    options: {},
    search: '',
    loading: false,
    displayAlias: false,
    showOnlyMyItems: false,
    modeType: '',
    textCut: 400,
    tab: 1,
    test1: false,
    test2: false,
    test3: false,
    testAdd: [
      'Header',
      'Agenda',
      'Call to Order',
      'Pledge of Allegiance',
      'Minutes',
      'Correspondence & Comments',
      'Consent Agenda',
      'Old Business',
      'New Business',
      'Items out of order',
      'Adjournment',
      'Accessible Statement',
      'Moment of Silence',
      'Executive Session'
    ],
    breadcrumbsItems: [
      {
        text: 'Items',
        disabled: true,
        href: ''
      }
    ],
    modals: {
      create: false,
      edit: false,
      itemType: false,
      region: false,
      coverSheet: false,
      permissions: false,
      conversations: false,
      private: false,
      download: false,
      move: false,
      notPrivate: false,
      park: false,
      delete: false,
      view: false,
      coverSheetDraft: false
    },
    itemsMenuMeeting: [
      { icon: 'mdi-pencil', text: 'Edit Title / Alias / Tags', actions: 'edit' },
      { icon: 'mdi-format-list-bulleted-type', text: 'Change Item Type', actions: 'itemType' },
      { icon: 'mdi-table', text: 'Change Region', actions: 'region' },
      { icon: 'mdi-file', text: 'Cover Sheet', actions: 'coverSheet' },
      { icon: 'mdi-share', text: 'Share permissions', actions: 'permissions' },
      { icon: 'mdi-message-text-outline', text: 'Conversations and Notes', actions: 'conversations' },
      { icon: 'mdi-lock-outline', text: 'Set as private', actions: 'private' },
      { icon: 'mdi-download', text: 'Download Draft', actions: 'download' },
      { icon: 'mdi-undo-variant', text: 'Move to Another Meeting', actions: 'move' },
      { icon: 'mdi-lock-open-variant-outline', text: 'Not private', type: 'important', actions: 'notPrivate' },
      { icon: 'mdi-sign-caution', text: 'Park Item', type: 'important', actions: 'park' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    itemsMenuParked: [
      { icon: 'mdi-pencil', text: 'Edit Title / Alias / Tags', actions: 'edit' },
      { icon: 'mdi-format-list-bulleted-type', text: 'Change Item Type', actions: 'itemType' },
      { icon: 'mdi-file', text: 'Cover Sheet', actions: 'coverSheet' },
      { icon: 'mdi-share', text: 'Share permissions', actions: 'permissions' },
      { icon: 'mdi-message-text-outline', text: 'Conversations and Notes', actions: 'conversations' },
      { icon: 'mdi-lock-outline', text: 'Set as private', actions: 'private' },
      { icon: 'mdi-download', text: 'Download Draft', actions: 'download' },
      { icon: 'mdi-undo-variant', text: ' Move to a Meeting', actions: 'move' },
      { icon: 'mdi-lock-open-variant-outline', text: 'Not private', type: 'important', actions: 'notPrivate' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: '', value: 'editDraft', width: '80px' },
      { text: 'Title', value: 'title' },
      { text: 'Board', value: 'board' },
      { text: 'Meeting', value: 'meeting', width: '180px' },
      { text: 'Tags', value: 'tags', width: '150px', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    itemSampleMeeting: [
      {
        title: 'Proin sed purus arcu. Nam ut elementum felis, vel convallis neque. Maecenas ultricies euismod urna vel suscipit. Aenean velit sem, posuere nec mauris in, interdum condimentum nisl. Donec sodales accumsan lacus id dignissim. Mauris sed odio lacus. Nullam arcu nulla, ultrices ac malesuada quis, euismod in turpis. Mauris vel facilisis ex, nec imperdiet tellus. Maecenas vel felis tincidunt, cursus diam ut, vehicula orci. Aliquam vestibulum, turpis quis scelerisque molestie, sapien ipsum laoreet velit, ut vehicula magna urna sit amet eros. Suspendisse faucibus condimentum lectus non vehicula. Morbi vehicula augue risus, dignissim cursus enim sollicitudin a. Aliquam et arcu justo.\n' +
          '\n' +
          'Duis congue turpis eu efficitur commodo. Maecenas accumsan consequat ante at semper. In eu facilisis ex. Donec convallis pellentesque est in semper. Integer ac ipsum vitae quam porta sollicitudin. Aliquam ut massa tempor, pretium metus et, gravida ante. Quisque faucibus et mi ut placerat. Sed vitae enim eget nisl lobortis sodales. Nullam ut nisi ligula. Curabitur vel elementum tellus. Vivamus sit amet est ut augue rutrum suscipit ac et neque. Mauris a massa ante. Vestibulum viverra feugiat diam, vitae vestibulum mi feugiat faucibus. Cras facilisis massa sem, sed rhoncus nisl pellentesque id. Praesent laoreet mauris eget interdum congue.',
        board: 'Planning and Zoning Commission',
        meeting: 'Regular Meeting',
        date: 'Sep 25,2020',
        time: '7:00PM',
        weekday: 'Tuesday',
        tags: [
          'Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5', 'Tag 6'
        ]
      },
      {
        title: 'Aenean velit sem, posuere nec mauris in, interdum condimentum nisl. Donec sodales accumsan lacus id dignissim.',
        board: 'Planning and Zoning Commission',
        meeting: 'Regular Meeting',
        date: 'Sep 25,2020',
        time: '7:00PM',
        weekday: 'Tuesday',
        tags: [
          'Tag 1', 'Tag 2'
        ]
      }
    ],
    itemSampleParked: [
      {
        title: 'Proin sed purus arcu. Nam ut elementum felis, sollicitudin. Aliquam ut massa tempor, pretium mes. Nutellus. Vivamus sit amet est ut augue rutrum suscipit ac et neque. Mauris a massa ante. Vestibulum viverra feugiat diam, vitae vestibulum mi feugiat faucibus. Cras facilisis massa sem, sed rhoncus nisl pellentesque id. Praesent laoreet mauris eget interdum congue.',
        board: 'Planning and Zoning Commission',
        meeting: 'Regular Meeting',
        date: 'Sep 25,2020',
        time: '7:00PM',
        weekday: 'Tuesday',
        tags: [
          'Tag 1'
        ]
      },
      {
        title: 'Aenean velit sem, posuere nec mauris in, interdum condimentum nisl. Donec sodales accumsan lacus id dignissim.',
        board: 'Planning and Zoning Commission',
        meeting: 'Regular Meeting',
        date: 'Sep 25,2020',
        time: '7:00PM',
        weekday: 'Tuesday',
        tags: [
          'Tag 1', 'Tag 2', 'Tag 3'
        ]
      }
    ],
    boardsSample: ['City Manager Office', 'City Secretary Office', 'EDC Board', 'Town Board', 'Architectural Review Board', 'Planning and Zoning Commission', 'Library Board'],
    boardSelected: 'City Manager Office'
  }
}
export default Vue.extend({
  name: 'Items',
  components: {
    Portal,
    ItemCreate,
    ItemEdit,
    ItemType,
    ItemChangeRegion,
    ItemCoverSheet,
    ItemCoverSheetDraft,
    ItemSharePermissions,
    ItemConversationsNotes,
    ItemPrivate,
    ItemMoveToMeeting,
    ItemNotPrivate,
    ItemPark,
    ItemDelete,
    ItemView
  },
  data: function () {
    return initialState()
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    filteredItems () {
      let items = []
      if (this.tab === 'tab-1') {
        items = this.items.filter(item => !item.is_parked)
        if (this.inAMeetingBoardSelected) {
          items = items.filter(item => item.board_id === this.inAMeetingBoardSelected)
        }
      } else {
        items = this.items.filter(item => item.is_parked)
        if (this.parkedBoardSelected) {
          items = items.filter(item => item.board_id === this.parkedBoardSelected)
        }
      }
      if (this.showOnlyMyItems) {
        return items.filter(item => item.user_id === this.user.id)
      }
      return items
    }
  },
  methods: {
    viewItem (item) {
      this.item = item
      this.modals.view = true
    },
    openModal (item, optional, selectedItem, board) {
      if (optional) this.modeType = optional
      // this.modals.edit = true
      this.modals[item] = true
      if (selectedItem) this.item = selectedItem
      if (board) this.board = board
    },
    itemAttachments (item) {
      this.$router.push({ name: 'ItemAttachments', params: { itemId: String(item.id) } })
    },
    coverSheetDraft (item) {
      this.item = item
      this.modals.coverSheetDraft = true
    },
    async getItems () {
      this.loading = true
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage
      } = this.options
      let orderBy = null
      if (sortBy !== undefined && sortBy[0] !== undefined) {
        const sortDir = sortDesc[0] ? '-' : ''
        orderBy = sortDir + sortBy[0]
      }

      const activeBoardIds = this.activeBoards.map(item => item.id)
      let itemQuery = Item
      let totalCountResponse = []
      let results = []

      // In a meeting
      if (this.tab === 'tab-1') {
        itemQuery = itemQuery.where('is_parked', 0)
        if (this.inAMeetingBoardSelected) {
          itemQuery = itemQuery.where('board_id', this.inAMeetingBoardSelected)
        }
      } else {
        itemQuery = itemQuery.where('is_parked', 1)
        if (this.parkedBoardSelected) {
          itemQuery = itemQuery.where('board_id', this.parkedBoardSelected)
        }
      }
      if (!this.inAMeetingBoardSelected && !this.parkedBoardSelected) {
        itemQuery = itemQuery.whereIn('board_id', activeBoardIds)
      }
      totalCountResponse = await itemQuery.get()
      results = await itemQuery.page(page ?? 1)
        .orderBy(orderBy)
        .limit(itemsPerPage ?? 10)
        .include(['board', 'user', 'coverSheet', 'itemType'])
        .get()

      this.items = results.data
      this.totalItemsCount = totalCountResponse.data.length
      this.loading = false
    },
    setTab () {
      return String(Math.random())
    },
    getActiveBoards () {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/boards-by-client-id/' + this.$store.state.client.activeClient.clientID)
        .then((res) => {
          this.activeBoards = res.data
        })
    },
    itemTitle (item) {
      if (this.displayAlias) {
        return item.alias ?? ''
      }
      return item.title
    },
    itemsMenuFiltered (item) {
      const itemsToRemove = []
      if (item.is_private) {
        itemsToRemove.push('private')
      }
      if (!item.is_private) {
        itemsToRemove.push('notPrivate')
      }
      if (item.is_parked) {
        itemsToRemove.push('park')
      }
      const items = this.tab === 'tab-1' ? this.itemsMenuMeeting : this.itemsMenuParked
      return items.filter(function (el) { return !itemsToRemove.includes(el.actions) })
    }
  },
  mounted () {
    this.getActiveBoards()
  },
  watch: {
    async options () {
      await this.getItems()
    },
    displayAlias () {
      this.$forceUpdate()
    },
    showOnlyMyItems () {
      this.$forceUpdate()
    },
    parkedBoardSelected () {
      this.boardName = this.activeBoards.find(item => item.id === this.parkedBoardSelected)?.name ?? ''
      this.getItems()
      this.$forceUpdate()
    },
    inAMeetingBoardSelected () {
      this.boardName = this.activeBoards.find(item => item.id === this.inAMeetingBoardSelected)?.name ?? ''
      this.getItems()
      this.$forceUpdate()
    },
    tab () {
      this.getItems()
    }
  }
})
</script>
<style>
.v-menu__content {
  overflow-y: unset !important;
  overflow-x: unset !important;
  contain: unset !important;
}
</style>
